import React, { useEffect, useState } from "react";
import HomeReview from "./home-review/HomeReview";
import styles from "./HomeReviews.module.css";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { getAllGeneralReviews } from "../../../../services/reviews/reviews";
import { IReviewGeneral } from "../../../../services/reviews/review.interface";

const HomeReviews: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [reviews, setReviews] = useState<IReviewGeneral[]>([]);
  const [isReviewsError, setReviewsError] = useState(false);

  const getAll = async () => {
    try {
      const reviewsData = await getAllGeneralReviews();
      setReviews(reviewsData);
      setReviewsError(false);
    } catch (error) {
      setReviewsError(true);
    }
  };

  useEffect(() => {
    getAll();
  }, []);

  return (
    <section
      className={`${styles.home__reviews_section} ${
        !isReviewsError ? `${styles.no__reviews}` : ""
      }`}
    >
      <div className={styles.container}>
        <div className={styles.home__reviews_wrapper}>
          <div className={styles.home__reviews_main}>
            <HomeReview
              key={"uniq1"}
              reviews={reviews}
              isReviewsError={isReviewsError}
            />
          </div>
          <NavLink
            to={`/${i18n.language}/individual-orthopedic-insoles`}
            className={styles.home__reviews_button}
            type="button"
          >
            {t("home.homeReviews.homeReviewsButtonText")}
          </NavLink>
        </div>
      </div>
    </section>
  );
};

export default HomeReviews;
