import { Routes, Route, useParams, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "./components/Layout";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import HomePage from "./pages/home/HomePage";
import AboutProstopoo from "./pages/about/about-prostopoo/AboutProstopoo";
import AboutContacts from "./pages/about/about-contacts/AboutContacts";
import AboutMade from "./pages/about/about-made/AboutMade";
import AboutWarranty from "./pages/about/about-warranty/AboutWarranty";
import AboutPolicy from "./pages/about/about-policy/AboutPolicy";
import AboutPublic from "./pages/about/about-public/AboutPublic";
import ClientNeed from "./pages/client/client-need/ClientNeed";
import ClientRecommendation from "./pages/client/client-recommendation/ClientRecommendation";
import OrderMake from "./pages/order/order-make/OrderMake";
import OrderPayment from "./pages/order/order-payment/OrderPayment";
import OrderDelivery from "./pages/order/order-delivery/OrderDelivery";
import CatalogIndividual from "./pages/catalog/catalog-individual/CatalogIndividual";
import CatalogOrthopedic from "./pages/catalog/catalog-orthopedic/CatalogOrthopedic";
import CatalogCertificate from "./pages/catalog/catalog-certificate/CatalogCertificate";
import CatalogProduct from "./pages/catalog/catalog-product/CatalogProduct";
import CartAbout from "./pages/cart/cart-about/CartAbout";
import CartDelivery from "./pages/cart/cart-delivery/CartDelivery";
import CartPayment from "./pages/cart/cart-payment/CartPayment";
import Admin from "./pages/admin/Admin";
import AdminLogin from "./pages/admin/admin-login/AdminLogin";
import AdminWorkerUpdate from "./pages/admin/components/admin-workers/components/admin-worker-update/AdminWorkerUpdate";
import AdminError from "./pages/admin/admin-error/AdminError";
import AdminProductUpdate from "./pages/admin/components/admin-products/components/admin-product-update/AdminProductUpdate";
import AdminReviewsUpdate from "./pages/admin/components/admin-reviews/components/admin-reviews-update/AdminReviewsUpdate";
import AdminProductVariationUpdate from "./pages/admin/components/admin-products/components/admin-product-update/components/admin-product-update-variations/components/admin-product-variation-update/AdminProductVariationUpdate";
import AdminProductsReviewsUpdate from "./pages/admin/components/admin-products/components/admin-product-update/components/admin-product-update-reviews/components/admin-products-reviews-update/AdminProductsReviewsUpdate";
import AdminIndividualInsolesUpdate from "./pages/admin/components/admin-individual-insoles/components/admin-individual-insoles-update/AdminIndividualInsolesUpdate";
import AdminIndividualInsolesVariationUpdate from "./pages/admin/components/admin-individual-insoles/components/admin-individual-insoles-update/components/admin-individual-insoles-update-variations/components/admin-individual-insoles-variation-update/AdminIndividualInsolesVariationUpdate";
import AdminCertificateUpdate from "./pages/admin/components/admin-certificate/components/admin-certificate-update/AdminCertificateUpdate";
import AdminFopUpdate from "./pages/admin/components/admin-fop/components/admin-fop-update/AdminFopUpdate";

const App = () => {
  const { i18n } = useTranslation();

  const LanguageWrapper = ({ children }: { children: React.ReactNode }) => {
    const { lang } = useParams();

    const supportedLanguages = ["ua", "en"];
    if (!supportedLanguages.includes(lang!)) {
      return <Navigate to={`/${i18n.language}`} />;
    }

    if (i18n.language !== lang) {
      i18n.changeLanguage(lang);
    }

    return <>{children}</>;
  };

  return (
    <Layout>
      <ScrollToTop />
      <Routes>
        <Route
          path="/:lang/*"
          element={
            <LanguageWrapper>
              <Routes>
                <Route path="*" element={<HomePage />} />
                <Route path="about" element={<AboutProstopoo />} />
                <Route path="contacts" element={<AboutContacts />} />
                <Route path="made" element={<AboutMade />} />
                <Route
                  path="warranty-and-exchange"
                  element={<AboutWarranty />}
                />
                <Route path="privacy-policy" element={<AboutPolicy />} />
                <Route
                  path="public-offer-agreement"
                  element={<AboutPublic />}
                />
                <Route
                  path="who-need-individual-orthopedic-insoles"
                  element={<ClientNeed />}
                />
                <Route
                  path="recommendations"
                  element={<ClientRecommendation />}
                />
                <Route path="how-to-make-a-order" element={<OrderMake />} />
                <Route path="payment" element={<OrderPayment />} />
                <Route path="delivery" element={<OrderDelivery />} />
                <Route
                  path="individual-orthopedic-insoles"
                  element={<CatalogIndividual />}
                />
                <Route
                  path="orthopedic-insoles"
                  element={<CatalogOrthopedic />}
                />
                <Route
                  path="orthopedic-insoles/:id"
                  element={<CatalogProduct />}
                />
                <Route
                  path="gift-certificate-prostopoo"
                  element={<CatalogCertificate />}
                />
                <Route path="cart" element={<CartAbout />} />
                <Route
                  path="cart/contacts-and-delivery"
                  element={<CartDelivery />}
                />
                <Route path="cart/payment" element={<CartPayment />} />
              </Routes>
            </LanguageWrapper>
          }
        />
        <Route path="prostopoo-admin-panel" element={<Admin />} />
        <Route
          path="prostopoo-admin-panel/update-worker/:id"
          element={<AdminWorkerUpdate />}
        />
        <Route
          path="prostopoo-admin-panel/update-product/:id"
          element={<AdminProductUpdate />}
        />
        <Route
          path="prostopoo-admin-panel/product-variation-update/:id"
          element={<AdminProductVariationUpdate />}
        />
        <Route
          path="prostopoo-admin-panel/product-review-update/:id"
          element={<AdminProductsReviewsUpdate />}
        />
        <Route
          path="prostopoo-admin-panel/update-general-review/:id"
          element={<AdminReviewsUpdate />}
        />
        <Route
          path="prostopoo-admin-panel/update-individual/:id"
          element={<AdminIndividualInsolesUpdate />}
        />
        <Route
          path="prostopoo-admin-panel/individual-insoles-variation-update/:id"
          element={<AdminIndividualInsolesVariationUpdate />}
        />
        <Route
          path="prostopoo-admin-panel/update-certificate/:id"
          element={<AdminCertificateUpdate />}
        />
        <Route
          path="prostopoo-admin-panel/update-fop/:id"
          element={<AdminFopUpdate />}
        />
        <Route path="prostopoo-admin-panel/login" element={<AdminLogin />} />
        <Route path="prostopoo-admin-panel/error" element={<AdminError />} />
        <Route path="*" element={<Navigate to={`/${i18n.language}`} />} />
      </Routes>
    </Layout>
  );
};

export default App;
